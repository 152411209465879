<template>
  <div>
    <b-modal
      id="modal-edit-sports-team"
      cancel-variant="outline-secondary"
      :ok-title="$t('Update')"
      :cancel-title="$t('Close')"
      ok-variant="primary"
      centered
      no-enforce-focus
      modal-class="modal-primary"
      :title="$t('Update Sport Team')"
      @ok="confirm"
      @hidden="resetModal()"
    >
      <!-- form -->
      <validation-observer ref="updatePostModal">
        <b-form method="POST" @submit.prevent="validationForm">
          <b-form-group label-for="title">
            <label class="mb-1">{{ $t("Name ") }}</label>
            <validation-provider #default="{ errors }" name="title">
              <b-form-input
                id="title"
                v-model="post.name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="title_en">
            <label class="mb-1">{{ $t("Sport Type Id") }}</label>
            <validation-provider #default="{ errors }" name="title_en">
              <v-select
                v-model="post.typeId"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sportTypeId"
                :reduce="(val) => val.id"
                :clearable="false"
                placeholder="Select category"
                label="name"
                input-id="category"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label-for="logo">
            <label for=""
              ><span class="text-danger">*</span> {{ $t("Logo") }}
              <span class="text-secondary">(Không > 10Mb)</span></label
            >
            <validation-provider
              #default="{ errors }"
              name="logo"
              rules="size:10240"
            >
              <b-form-file
                v-model="post.logo"
                input-id="logo"
                accept="image/jpeg,image/png,image/gif,image/svg,image/jpg"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <b-img
              :src="post.showImg"
              fluid
              style="width: 150px"
              class="mr-2 rounded"
            />
          </b-form-group>
          <b-button
            v-show="post.logo"
            variant="outline-danger"
            class="btn-sm btn-icon"
            @click="post.logo = null"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
  BLink,
  BImg,
  BRow,
  BCol,
  BTabs,
  BTab,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormFile,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";
import baseUrl from "@/libs/base-url";
import Editor from "@tinymce/tinymce-vue";
import API_KEY from "@/auth/apikey/apikeyeditor";
export default {
  components: {
    BLink,
    BImg,
    BRow,
    BCol,
    BTabs,
    BTab,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormFile,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BFormTextarea,
    BBadge,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    Editor,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    sportTypeId: {
      type: Array,
      default: null,
    },
    sportTeamDetail: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      API_KEY,
      post: {
        name: "",
        typeId: "",
        logo: "",
        showImg: null,
      },
      init: {
        height: 600,
        content_style:
          this.$store.state.appConfig.layout.skin === "dark"
            ? "body { color: white; background-color:#293046 }"
            : "",
        plugins: [
          "advlist",
          "autolink",
          "lists",
          "link",
          "image",
          "charmap",
          "preview",
          "anchor",
          "searchreplace",
          "visualblocks",
          "code",
          "fullscreen",
          "insertdatetime",
          "media",
          "table",
          "wordcount",
        ],
        toolbar:
          " undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent inden |image",
        skin:
          this.$store.state.appConfig.layout.skin === "dark"
            ? "oxide-dark"
            : "",
        image_title: true,
        automatic_uploads: true,
        file_picker_types: "image",
        file_picker_callback: function (cb, value, meta) {
          var input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");
          input.onchange = function () {
            var file = this.files[0];

            var reader = new FileReader();
            reader.onload = function () {
              var id = "blobid" + new Date().getTime();
              var blobCache = tinymce.activeEditor.editorUpload.blobCache;
              var base64 = reader.result.split(",")[1];
              var blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);
              cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
          };

          input.click();
        },
      },
    };
  },
  watch: {
    sportTeamDetail: {
      
      handler(newVal) {
        if (newVal) {
          this.post = {
            id: newVal.id,
            name: newVal.name,
            typeId: newVal.sport_type_id,
            logo: "",
            showImg: baseUrl + newVal.logo,
          };
        }
      },
      deep: true,
    },
  },
  methods: {
    validationForm() {
      this.$refs.updatePostModal.validate().then((success) => {
        if (success) {
          this.updatePost(this.post);
        }
      });
    },
    async updatePost(postData) {
      await this.$store
        .dispatch("sports/updateSportTeam", postData)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.code == "00") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || "success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.$emit("refetch-data");
              this.$nextTick(() => {
                setTimeout(() => {
                  this.$bvModal.hide("modal-edit-sports-team");
                  this.resetModal();
                }, "500");
              });
              this.$emit("refetch-data");
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          }
        });
    },
     resolveSportType (status){

       if (status == 1) return { name: "Bóng Đá" };
       return { label: "Pending", };
     },
    resetModal() {
      this.post = {
        name: "",
        typeId: "",
        logo: null,
      };
      this.$emit("update:sportTeamDetail", null);
    },
    confirm(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationForm();
    },
  },
  setup() {
    return {};
  },
};
</script>
