import AgentService from "@/libs/agent.service";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAgents(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        AgentService.getAgentLists(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateAgentWallet(ctx, agentData) {
      return new Promise((resolve, reject) => {
        AgentService.updateAgentWallet(agentData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchGameTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        AgentService.getGameTypeLists(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateGameType(ctx, gameTypeData) {
      return new Promise((resolve, reject) => {
        AgentService.updateGameType(gameTypeData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchGameCategory(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        AgentService.fetchGameCategoryLists(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
	updateGameCategory(ctx, agentData) {
		return new Promise((resolve, reject) => {
		  AgentService.updateGameCategory(agentData)
			.then((response) => resolve(response))
			.catch((error) => reject(error));
		});
	  },
    fetchGameTypeFilters(ctx) {
			return new Promise((resolve, reject) => {
				AgentService.getGameTypeFilters()
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
    fetchWinLossByUser(ctx, queryParams) {
        return new Promise((resolve, reject) => {
            AgentService.fetchAgentWinLoss(queryParams)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    },
    fetchWinLossByProduct(ctx, queryParams) {
        return new Promise((resolve, reject) => {
            AgentService.fetchAgentWinLossProduct(queryParams)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
        });
    },
    fetchWinLossGeneral(ctx, queryParams) {
      return new Promise((resolve, reject) => {
          AgentService.fetchAgentWinLossGeneral(queryParams)
              .then((response) => resolve(response))
              .catch((error) => reject(error));
      });
    },
    fetchWinLossGeneralDetail(ctx, queryParams) {
      return new Promise((resolve, reject) => {
          AgentService.fetchAgentWinLossGeneralDetail(queryParams)
              .then((response) => resolve(response))
              .catch((error) => reject(error));
      });
    },
  },
};
