/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import instance from "@/libs/axios.instance";
import authHeader from "@/libs/auth.header";

class AgentService {
  getAgentLists(queryParams) {
    const pars = {
      id: queryParams.id,
      status: queryParams.status,
      pageNumber: queryParams.page,
    };
    return instance.get("/api/agent/index", {
      headers: authHeader(),
      params: pars,
    });
  }

  // updateAgentWallet(agentData) {
  //   const FormData = require("form-data");
  //   const data = new FormData();
  //   if (
  //     agentData.start_maintenance &&
  //     agentData.end_maintenance &&
  //     agentData.start_end_maintenance &&
  //     agentData.start_maintenance !== "null" &&
  //     agentData.end_maintenance !== "null" &&
  //     agentData.start_end_maintenance !== "null"
  //   ) {
  //     data.append("start_maintenance", agentData.start_maintenance);
  //     data.append("end_maintenance", agentData.end_maintenance);
  //   }
  //   data.append("id", agentData.id);
  //   data.append("wallet_name", agentData.wallet_name);
  //   data.append("status", agentData.status);
  //   if (agentData.logo.length > 0 && typeof agentData.logo !== "string") {
  //     for (let i = 0; i < agentData.logo.length; i++) {
  //       data.append("logo", agentData.logo[i]);
  //     }
  //   }
  //  else{
  //     console.log(12312312312);
  //     // return
  //   }
    
  //   return instance.post("/api/agent/update", data, { headers: authHeader() });
  // }
  getAgentLists(queryParams) {
    const pars = {
      id: queryParams.id,
      status: queryParams.status,
      pageNumber: queryParams.page,
    };
    return instance.get("/api/agent/index", {
      headers: authHeader(),
      params: pars,
    });
  }

  updateAgentWallet(agentData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("id", agentData.id);
    data.append("wallet_name", agentData.wallet_name);
    data.append("status", agentData.status);
    if (
      agentData.start_maintenance &&
      agentData.end_maintenance
    ) {
      data.append("start_maintenance", agentData.start_maintenance);
      data.append("end_maintenance", agentData.end_maintenance);
    }
    
    for (let i = 0; i < agentData.logo.length; i++) {
      if (typeof agentData.logo[i] !== "string") {
        data.append("logo", agentData.logo[i]);
      }
    }
    return instance.post("/api/agent/update", data, { headers: authHeader() });
  }

  getGameTypeLists(queryParams) {
    const pars = {
      name: queryParams.name,
      type: queryParams.type,
      pageNumber: queryParams.page,
      code: queryParams.code,
      status: queryParams.status,
    };
    return instance.get("/api/game-type/index", {
      headers: authHeader(),
      params: pars,
    });
  }

  updateGameType(gameTypeData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("game_type_id", gameTypeData.game_type_id);
    data.append("img", gameTypeData.img);
    data.append("title", gameTypeData.title);
    data.append("player_number", gameTypeData.player_number);
    data.append("order", gameTypeData.order);
    data.append("status", gameTypeData.status);
    return instance.post("/api/game-type/update", data, {
      headers: authHeader(),
    });
  }

  fetchGameCategoryLists(queryParams) {
    const pars = {
      id: queryParams.id,
      status: queryParams.status,
      pageNumber: queryParams.page,
    };
    return instance.get("/api/game-category/index", {
      headers: authHeader(),
      params: pars,
    });
  }
  updateGameCategory(gameTypeData) {
    const FormData = require("form-data");
    const data = new FormData();

    data.append("id", gameTypeData.id);
    data.append("name", gameTypeData.name);
    data.append("name_en", gameTypeData.nameEn); 
    data.append("banner", gameTypeData.banner);

    return instance.post("/api/game-category/update", data, {
      headers: authHeader(),
    });
  }

  getGameTypeFilters() {
		return instance.get('/api/agent/index', { headers: authHeader() })
  }

  fetchAgentWinLoss(queryParams) {
    const pars = {
      username: queryParams.username,
      productId: queryParams.productId,
      fromDate: queryParams.fromDate,
      toDate: queryParams.toDate
    };
    return instance.get("/api/agent/win-loss-by-user", {
      headers: authHeader(),
      params: pars,
    });
  }

  fetchAgentWinLossProduct(queryParams) {
    const pars = {
      game_type_id: queryParams.gameTypeId,
      productId: queryParams.productId,
      fromDate: queryParams.fromDate,
      toDate: queryParams.toDate
    };
    return instance.get("/api/agent/win-loss-by-product", {
      headers: authHeader(),
      params: pars,
    });
  }

  fetchAgentWinLossGeneral(queryParams) {
    const pars = {
      fromDate: queryParams.fromDate,
      toDate: queryParams.toDate
    };
    return instance.get("/api/agent/win-loss-general", {
      headers: authHeader(),
      params: pars,
    });
  }

  fetchAgentWinLossGeneralDetail(queryParams) {
    const pars = {
      fromDate: queryParams.fromDate,
      toDate: queryParams.toDate,
      productId: queryParams.productId,
      username: queryParams.username,
    };
    return instance.get("/api/agent/win-loss-general-detail", {
      headers: authHeader(),
      params: pars,
    });
  }
}

export default new AgentService();
