/* eslint-disable indent */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable global-require */
/* eslint-disable no-useless-concat */
/* eslint-disable no-useless-escape */
// import axios from 'axios'
import instance from "@/libs/axios.instance";
import authHeader from "@/libs/auth.header";

class SportService {
  getSportLeagues(queryParams) {
    const pars = {
      name: queryParams.name,
      name_en: queryParams.name_en,
      pageNumber: queryParams.page,
      //   status: queryParams.status,
    };
    return instance
      .get("/api/sport-league/index", { headers: authHeader(), params: pars })
      .then((response) => {
        const datas = {
          sportLeagues: response.data.data,
          total: response.data.count,
          pageSize: queryParams.page == 1 ? response.data.data.length : 25,
        };
        return datas;
      });
  }
  addSportLeague(sportLeagueData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("name", sportLeagueData.name);
    data.append("logo", sportLeagueData.logo);

    return instance.post("/api/sport-league/create", data, {
      headers: authHeader(),
    });
  }
  getSportType(queryParams) {
    const pars = {
      name: queryParams.name,
      name_en: queryParams.name_en,
      pageNumber: queryParams.page,
      //   status: queryParams.status,
    };
    return instance
      .get("/api/sport-type/index", { headers: authHeader(), params: pars })
      .then((response) => {
        const datas = {
          sportType: response.data.data,
          total: response.data.count,
          pageSize: queryParams.page == 1 ? response.data.data.length : 25,
        };
        return datas;
      });
  }
  getSportSeason(queryParams) {
    const pars = {
      name: queryParams.name,
      // name_en: queryParams.name_en,
      pageNumber: queryParams.page,
      //   status: queryParams.status,
    };
    return instance
      .get("/api/sport-season/index", { headers: authHeader(), params: pars })
      .then((response) => {
        const datas = {
          sportSeason: response.data.data,
          total: response.data.count,
          pageSize: queryParams.page == 1 ? response.data.data.length : 25,
        };
        return datas;
      });
  }
  getSportTeam(queryParams) {
    const pars = {
      name: queryParams.name,
      sport_type_id: queryParams.sport_type_id,
      pageNumber: queryParams.page,
      //   status: queryParams.status,
    };
    return instance
      .get("/api/sport-team/index", { headers: authHeader(), params: pars })
      .then((response) => {
        const datas = {
          sportTeam: response.data.data,
          total: response.data.count,
          pageSize: queryParams.page == 1 ? response.data.data.length : 25,
        };
        return datas;
      });
  }
  addSportTeamList(sportTeamData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("name", sportTeamData.name);
    data.append("sport_type_id", sportTeamData.typeId);
    data.append("logo", sportTeamData.logo);

    return instance.post("/api/sport-team/create", data, {
      headers: authHeader(),
    });
  }
  updateSportTeam(postData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("id", postData.id);
    data.append("name", postData.name);
    data.append("sport_type_id", postData.typeId);
    data.append("logo", postData.logo);
    return instance.post("/api/sport-team/update", data, {
      headers: authHeader(),
    });
  }

  getAccounting(queryParams) {
    const pars = {
      fromDate: queryParams.fromDate,
      toDate: queryParams.toDate,
      // pageNumber: queryParams.page,
      //   status: queryParams.status,
    };
    return instance
      .get("/api/accounting/index", { headers: authHeader(), params: pars })
      .then((response) => {
        const datas = {
          account: response.data.data,
          // total: response.data.count,
          // pageSize: queryParams.page == 1 ? response.data.data.length : 25,
        };
        return datas;
      });
  }
  postResetPassword(sportTeamData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("password", sportTeamData.password);
    data.append("new_password", sportTeamData.cPassword);
    // data.append('logo', sportTeamData.logo)

    return instance.post("/api/staff/change-password", data, {
      headers: authHeader(),
    });
  }
  getAccountingWinLoss(queryParams) {
    const pars = {
      productId: queryParams.productId,
      fromDate: queryParams.fromDate,
      toDate: queryParams.toDate,
      pageNumber: queryParams.page,
      //   status: queryParams.status,
    };
    return instance
      .get("/api/accounting/win-loss", { headers: authHeader(), params: pars })
      .then((response) => {
        const datas = {
          sportTeam: response.data.data,
          total: response.data.count,
          pageSize: queryParams.page == 1 ? response.data?.data?.length : 25,
        };
        return datas;
      });
  }

  getBonusCodeProduct() {
    return instance.get("/api/bonus-code/product/index", {
      headers: authHeader(),
    });
  }

  getSportHotMarch(param) {
    const pars = {
      league_id: param.league_id,
      sport_type_id: param.sport_type_id,
      home_team_id: param.home_team_id,
      away_team_id: param.away_team_id,
      time_start: param.time_start,
      time_end: param.time_end,
      param_extensions: param.param_extensions,
      status: param.status,
      pageNumber: param.pageNumber,
    };
    return instance.get("/api/sport-hot-match/index", {
      headers: authHeader(), params: pars
    });
  }

  createSportHotMarch(sportTeamData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("league_id", sportTeamData.league_id);
    data.append("sport_type_id", sportTeamData.sport_type_id);
    data.append("home_team_id", sportTeamData.home_team_id);
    data.append("away_team_id", sportTeamData.away_team_id);
    data.append("time_start", sportTeamData.time_start);
    data.append("time_end", sportTeamData.time_end);
    data.append("param_extensions", sportTeamData.param_extensions);
    data.append("status", sportTeamData.status);
    if (sportTeamData.link_live_stream) {
      data.append("link_live_stream", sportTeamData.link_live_stream);
    }

    return instance.post("/api/sport-hot-match/create", data, {
      headers: authHeader(),
    });
  }

  updateSportHotMarch(sportTeamData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("id", sportTeamData.id);
    data.append("league_id", sportTeamData.league_id);
    data.append("sport_type_id", sportTeamData.sport_type_id);
    data.append("home_team_id", sportTeamData.home_team_id);
    data.append("away_team_id", sportTeamData.away_team_id);
    data.append("time_start", sportTeamData.time_start);
    data.append("time_end", sportTeamData.time_end);
    data.append("param_extensions", sportTeamData.param_extensions);
    data.append("status", sportTeamData.status);
    if (sportTeamData.link_live_stream) {
      data.append("link_live_stream", sportTeamData.link_live_stream);
    }

    return instance.post("/api/sport-hot-match/update", data, {
      headers: authHeader(),  
    });
  }

  getBvSportHotMarch(param) {
    const pars = {
      match_id: param.match_id,
      hide: param.hide,
      hot: param.hot,
      league_title: param.league_title,
      localteam_title: param.localteam_title,
      visitorteam_title: param.visitorteam_title,
      pageNumber: param.pageNumber,
    };
    return instance.get("/api/bv-sport-hot-match/index", {
      headers: authHeader(), params: pars
    });
  }

  updateBvSportHotMarch(sportTeamData) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("id", sportTeamData.id);
    data.append("hide", sportTeamData.hide);
    data.append("hot", sportTeamData.hot);
    if (sportTeamData.link_live_stream) {
      data.append("link_live_stream", sportTeamData.link_live_stream);
    }
    if (sportTeamData.param_extensions) {
      data.append("param_extensions", sportTeamData.param_extensions);
    }

    return instance.post("/api/bv-sport-hot-match/update", data, {
      headers: authHeader(),
    });
  }
}

export default new SportService();
