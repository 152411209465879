import SportService from "@/libs/sport.service";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSportLeagues(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        SportService.getSportLeagues(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addSportLeague(ctx, sportTeamData) {
      return new Promise((resolve, reject) => {
        SportService.addSportLeague(sportTeamData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSportType(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        SportService.getSportType(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSportSeason(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        SportService.getSportSeason(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSportTeam(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        SportService.getSportTeam(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addSportsTeam(ctx, sportTeamData) {
      return new Promise((resolve, reject) => {
        SportService.addSportTeamList(sportTeamData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateSportTeam(ctx, postData) {
      return new Promise((resolve, reject) => {
        SportService.updateSportTeam(postData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchAccounting(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        SportService.getAccounting(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchAccountingWinLoss(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        SportService.getAccountingWinLoss(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchBonusCodeProduct(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        SportService.getBonusCodeProduct(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    resetPassword(ctx, sportTeamData) {
      return new Promise((resolve, reject) => {
        SportService.postResetPassword(sportTeamData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchSportHotMatch(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        SportService.getSportHotMarch(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    createSportHotMatch(ctx, sportTeamData) {
      return new Promise((resolve, reject) => {
        SportService.createSportHotMarch(sportTeamData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    editSportHotMatch(ctx, sportTeamData) {
      return new Promise((resolve, reject) => {
        SportService.updateSportHotMarch(sportTeamData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchBvSportHotMatch(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        SportService.getBvSportHotMarch(queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    editBvSportHotMatch(ctx, sportTeamData) {
      return new Promise((resolve, reject) => {
        SportService.updateBvSportHotMarch(sportTeamData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
